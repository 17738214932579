import { useSelector } from 'react-redux';
import React, { Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TClient, TPresentationType } from '../../types';
import { URLS } from './dash/view/constants/URLS';
import { Button } from '../../components/Common/V2/Button/Button';
import { useUrlSearchParam } from '../../hooks/useQueryParams';
import { usePresentationCreator } from './hooks/usePresentationCreator';
import { usePresentationCreationQuery } from '../../hooks/usePresentationCreationQuery';
import { SaveReportMessageModal } from './SaveReportMessageModal/SaveReportMessageModal.component';
import { usePresentationFromParams } from '../../features/report/hooks/usePresentationFromParams';
import { useUpdatePresentation } from '../../hooks/useUpdatePresentation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PresentationApi } from '../Presentation/api/PresentationApi';
import { ReportDetailedCommunicator } from './dash/communicators/ReportDetailed/ReportDetailedCommunicator';
import { criteriaToApiMapper } from './dash/view/pages/Authenticated/PropertySearch/SearchApiMapper';

const ACTIONS = {
  CREATE_REGULAR: 'CREATE_REGULAR',
  CREATE_CMA: 'CREATE_CMA',
  UPDATE: 'UPDATE',
} as const;

export const BuildCMAActions: React.FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const presentationType = useUrlSearchParam('type') as TPresentationType | null;
  const action = useUrlSearchParam('action');

  const { isRebuild, presentation: presentationHashQuery } = usePresentationCreationQuery();

  const reportData = useSelector<any>(({ report, result, search }) => ({ report, result, search }));
  const clients = useSelector((state: any) => state.search.clients);

  const { getCreatePresentationPayload } = usePresentationCreator();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState('');
  const startLoading = (button?: string) => setIsLoading(button ?? 'regular');
  const stopLoading = () => setIsLoading('');

  const [createdPresentationId, setCreatedPresentationId] = useState('');
  const [createdPresentationClient, setCreatedPresentationClient] = useState<TClient | null>(null);
  const [createdPresentationHash, setCreatedPresentationHash] = useState('');

  const { data: presentation } = usePresentationFromParams();

  const { mutateAsync: updatePresentation } = useUpdatePresentation(presentation?.hash);

  const getClient = () => createdPresentationClient ?? clients;

  const onCreateClick = async () => {
    startLoading(ACTIONS.CREATE_REGULAR);

    try {
      if (!presentation?.id) return;

      const res = await updatePresentation({
        ...presentation,
        reportData,
      });

      await PresentationApi.triggerPdfGeneration(presentation.id);

      push(`/presentation/${res?.hash}/edit`);
    } catch (e: any) {
      console.error(e);
    } finally {
      stopLoading();
    }
  };

  const onCmaCreateClick = async () => {
    if (!presentation) return;
    startLoading(ACTIONS.CREATE_CMA);

    try {
      const api = criteriaToApiMapper((reportData as any).search.searchCriteria);

      const reportDetailed = await ReportDetailedCommunicator.build({
        ...api,
        mode: 'cma',
        presentation_type: presentation?.type,
        presentation_id: presentation?.id,
        rebuild: true,
      });

      const payload = await getCreatePresentationPayload('cma');

      const res = await updatePresentation({
        ...payload,
        id: reportDetailed.presentationId,
      });

      await PresentationApi.triggerPdfGeneration(reportDetailed.presentationId);

      const client = res.clientId
        ? { name: res.clientName, id: res.clientId, email: res.clientEmail }
        : null;

      setCreatedPresentationHash(res?.hash || '');
      setCreatedPresentationId(res?.id || '');
      setCreatedPresentationClient(client);
      setIsModalOpen(true);
    } catch (e: any) {
      console.error(e);
    } finally {
      stopLoading();
    }
  };

  const onPrev = () => {
    const params = new URLSearchParams();
    if (presentationType) {
      params.append('type', presentationType);
    }
    if (isRebuild) {
      params.delete('presentation');
      params.append('presentation', presentationHashQuery ?? '');
      params.append('action', 'rebuild');
    }

    const p = params.toString();
    push(`${URLS.SEARCH.DETAILS}${!p.length ? '' : '?' + p}`);
  };

  return (
    <Fragment>
      {pathname === '/create/result' && (
        <>
          <Button
            variant='secondary'
            prefixIcon={<FontAwesomeIcon icon={'chevron-left'} style={{ marginTop: '1px' }} />}
            onClick={onPrev}
            style={{ marginRight: 10 }}
            disabled={!!isLoading}
          >
            Previous
          </Button>

          <Button
            disabled={!!isLoading}
            variant='primary'
            onClick={onCmaCreateClick}
            style={{ marginRight: 10, width: 134, padding: '0 10px', justifyContent: 'center' }}
            isLoading={isLoading === ACTIONS.CREATE_CMA}
          >
            Save CMA Only
          </Button>
        </>
      )}

      <Button
        disabled={!pathname.startsWith('/create/result') || !!isLoading}
        variant='primary'
        onClick={onCreateClick}
        isLoading={isLoading === ACTIONS.CREATE_REGULAR}
        style={{ width: 163, padding: '0 10px', justifyContent: 'center' }}
      >
        {action === 'update' ? 'Update' : 'Create'} Presentation
      </Button>

      <SaveReportMessageModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        presentationClient={createdPresentationClient}
        presentationId={createdPresentationId}
        setCreatedPresentationClient={setCreatedPresentationClient}
        presentationType={presentationType}
        hash={createdPresentationHash}
      />
    </Fragment>
  );
};
