import { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '../../../../../../components/Common/V2/Button/Button';

import circleChekmarkBt from '../../../../../../assets/icons/circle_checkmark_bt.svg';
import { FEATURES, useHasFeatureFlag } from '../../../../../../hooks/useFeatureFlags';
import { useWhiteLabelSettings } from '../../../../../../features/whitelabel/useWhiteLabelSettings';

type Props = {
  title: string;
  edited: boolean;
  completed: boolean;
  section: 'customize' | 'personalize';
  onButtonClick: () => void;
  locked?: boolean;
};

export function CustomizationSettingsItem({
  title,
  edited,
  completed,
  section,
  locked,
  onButtonClick,
}: Props) {
  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);
  const theme = useTheme();
  const { hasWL } = useWhiteLabelSettings();

  const IconComponent = useMemo(() => {
    if (locked) {
      return <Icon icon='lock' />;
    }

    if (!completed) {
      return null;
    }

    if (hasBtTheme && !hasWL) {
      return <CheckIcon src={circleChekmarkBt} />;
    }

    return <Icon icon={['far', 'circle-check']} color={theme.colors.v2.accent} />;
  }, [locked, completed, hasBtTheme, hasWL, theme]);

  return (
    <Container>
      <LeftSection>
        {IconComponent}
        <Text>{title}</Text>
      </LeftSection>
      {!locked && (
        <Button variant={edited ? 'secondary' : 'primary'} onClick={onButtonClick}>
          {edited ? 'Edit' : section === 'customize' ? 'Customize' : 'Personalize'}
        </Button>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.v2.background};
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;

const LeftSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

const CheckIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-top: 2px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2px;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;
