import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateAtomOnChange } from '../../../hooks/useUpdateAtomOnChange';
import { extractSubject } from '../services/extractSubject';
import { subjectAtom } from '../state/subjectAtom';
import { searchCriteriaAtom } from '../state/searchCriteriaAtom';
import { highlightedPropertiesAtom } from '../state/highlighted-properties/highlightedPropertiesAtom';
import { thumbsUpAtom } from '../state/thumbsUpAtom';
import { excludedPropertyIdsAtom } from '../state/excludedPropertyIdsAtom';
import { isPresentationViewAtom } from '../state/isPresentationViewAtom';
import { compAveragesAtom } from '../state/compAveragesAtom';
import { estimatesAtom } from '../state/estimatesAtom';
import { selectedCategoriesAtom } from '../state/selectedCategoriesAtom';
import { flagsAtom } from '../state/flagsAtom';
import { customMarkersAtom } from '../state/customMarkersAtom';
import { customEstimatesAtom } from '../state/customEstimatesAtom';
import { searchAPIAtom } from '../state/searchAPIAtom';
import { useSetProperties } from './useSetProperties';
import { useSetNotes } from './useSetNotes';
import { competitionGraphAtom } from '../state/competitionGraphAtom';
import { isDtcAtom } from '../state/isDtcAtom';
import { useSetGraphs } from './useSetGraphs';

export const useSyncStateWithRedux = () => {
  const search = useSelector((state: any) => state.search);
  const report = useSelector((state: any) => state.report);
  const result = useSelector((state: any) => state.result);

  const subject = useMemo(() => extractSubject(report.reportDetailed), [report.reportDetailed]);
  useUpdateAtomOnChange(subjectAtom, subject);

  useSetProperties();
  useSetNotes();
  useSetGraphs();

  useUpdateAtomOnChange(searchCriteriaAtom, search.searchCriteria);
  useUpdateAtomOnChange(highlightedPropertiesAtom, result.highlighted);
  useUpdateAtomOnChange(thumbsUpAtom, result.thumbsUp);
  useUpdateAtomOnChange(excludedPropertyIdsAtom, result.excluded);
  useUpdateAtomOnChange(compAveragesAtom, report.reportDetailed.compAverages);
  useUpdateAtomOnChange(estimatesAtom, report.reportDetailed.estimates);
  useUpdateAtomOnChange(selectedCategoriesAtom, result.selectedCategories);
  useUpdateAtomOnChange(flagsAtom, report.reportDetailed.flags);
  useUpdateAtomOnChange(customMarkersAtom, result.customMarkers);
  useUpdateAtomOnChange(customEstimatesAtom, result.customEstimates);
  useUpdateAtomOnChange(searchAPIAtom, search.api);
  useUpdateAtomOnChange(competitionGraphAtom, report.reportDetailed.graphs.competition);
  useUpdateAtomOnChange(isDtcAtom, report.reportDetailed.useDtcInsteadDomForSold);
  useUpdateAtomOnChange(isPresentationViewAtom, false);
};
