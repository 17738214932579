import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuthMe } from '../../hooks/useAuthMe';
import { useUrlSearchParam } from '../../hooks/useQueryParams';
import { StorageService } from '../../services/storageService';
import { useHasFeatureFlag, FEATURES } from '../../hooks/useFeatureFlags';
import { hasNoWhiteLabelSettingsAtom } from './hasNoWhiteLabelSettingsAtom';
import { constructWhitelabelLoginUrl } from './constructWhitelabelLoginUrl';

export function useRedirectToWhiteLabel() {
  const { data: me } = useAuthMe();
  const token = StorageService.read('token');
  const noWhiteLabelSettings = useUrlSearchParam('noWhiteLabelSettings');
  const [hasNoWhiteLabelSetting, setHasNoWhiteLabelSettings] = useAtom(hasNoWhiteLabelSettingsAtom);
  const history = useHistory();

  const hasWhiteLabelFF = useHasFeatureFlag(FEATURES.BoldTrailWhiteLabel);

  useEffect(() => {
    if (!noWhiteLabelSettings) return;
    const params = new URLSearchParams();

    setHasNoWhiteLabelSettings(true);
    params.delete('noWhiteLabelSettings');
    history.push('/home');
  }, [history, noWhiteLabelSettings, setHasNoWhiteLabelSettings]);

  if (
    !hasWhiteLabelFF ||
    !token ||
    !me?.website ||
    me?.website?.toLowerCase() === window.location.hostname ||
    hasNoWhiteLabelSetting
  )
    return;

  window.location.href = constructWhitelabelLoginUrl(me.website);
}
