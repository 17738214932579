import { useEffect } from 'react';

import { TPresentationType, TProperty } from '../../../types';
import { useSetAtomState } from './useSetAtomState';

export const useResetAtomState = (type: TPresentationType) => {
  const {
    setIsPresentationView,
    setProperties,
    setSubject,
    setSearchCriteria,
    setHighlightedProperties,
    setThumbsUp,
    setExcluded,
    setNotes,
    setCustomMarkers,
    setPricingFavorability,
    setRateOfChange,
    setSellingPace,
    setActiveShelfLife,
    setCompAverages,
    setEstimates,
    setCustomEstimates,
    setCompetitionGraphData,
    setFlags,
    setIsDtc,
    setSelectedCategories,
  } = useSetAtomState();

  useEffect(() => {
    if (type === 'winTheRepresentation' || type === 'presentInfo') {
      setIsPresentationView(false);
      setProperties([]);
      setSubject({ lat: 0, lng: 0, address: '', property: {} as TProperty });
      setSearchCriteria(null);
      setHighlightedProperties([]);
      setThumbsUp(null);
      setExcluded([]);
      setNotes([]);
      setCustomMarkers([]);
      setPricingFavorability(null);
      setRateOfChange(null);
      setSellingPace(null);
      setActiveShelfLife(null);
      setCompAverages(null);
      setEstimates(null);
      setCustomEstimates([]);
      setCompetitionGraphData(null);
      setFlags(null);
      setIsDtc(false);
      setSelectedCategories([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
