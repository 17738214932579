import { useMutation } from '@tanstack/react-query';
import { usePresentationFromParams } from './usePresentationFromParams';
import { useDispatch, useStore } from 'react-redux';
import { resultOperations } from '../../../pages/PresentationCreate/dash/store/result';
import { useUpdatePresentationWithoutSaving } from '../../../hooks/useUpdatePresentationWithoutSaving';

export function useUpdatePresentationWithBuild() {
  const { data: presentation } = usePresentationFromParams();
  const { mutateAsync: updatePresentationWithoutSaving } = useUpdatePresentationWithoutSaving(
    presentation?.hash,
  );

  const dispatch = useDispatch();
  const getState = useStore().getState;

  return useMutation(async () => {
    await resultOperations.refreshBuild(presentation?.type, presentation?.id)(dispatch, getState);

    if (!presentation?.id) return;

    const reportData = getState();

    await updatePresentationWithoutSaving({
      ...presentation,
      reportData,
    });
  });
}
